import { FUEL_EXPLORER_URL } from "global-constants";
import React from "react";

const ViewonBlockhain = ({ id }: { id: string }) => {
  const link = FUEL_EXPLORER_URL + "/tx/" + id;

  return id ? (
    <a href={link} target="_blank" rel="noreferrer" className="body-medium !font-medium w-fit underline text-gray-light hover:text-white">
      View on Blockchain
    </a>
  ) : (
    <></>
  );
};

export default ViewonBlockhain;
