var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Provider, WalletUnlocked, Script, getMintedAssetId } from "fuels";
import { ThunderExchange } from "../../types/thunder_exchange";
import { StrategyFixedPriceSale } from "../../types/execution_strategies/strategy_fixed_price_sale";
import { Pool, } from "../../types/pool/";
import { ExecutionManager } from "../../types/execution_manager";
import { RoyaltyManager } from "../../types/royalty_manager";
import { AssetManager } from "../../types/asset_manager";
import { NFTContract } from "../../types/erc721";
import { SideInput } from "../../types/thunder_exchange/ThunderExchange";
import bytecode from "../../scripts/deposit_and_offer/binFile";
import abi from "../../scripts/deposit_and_offer/out/deposit_and_offer-abi.json";
let pool;
let executionManager;
let royaltyManager;
let assetManager;
let strategyFixedPrice;
export function setContracts(contracts, provider) {
    pool = new Pool(contracts.pool, provider);
    executionManager = new ExecutionManager(contracts.executionManager, provider);
    royaltyManager = new RoyaltyManager(contracts.royaltyManager, provider);
    assetManager = new AssetManager(contracts.assetManager, provider);
    strategyFixedPrice = new StrategyFixedPriceSale(contracts.strategyFixedPrice, provider);
}
function numberTo64Hex(num) {
    return '0x' + num.toString(16).padStart(64, '0');
}
function _convertToInput(makerOrder) {
    const subId = numberTo64Hex(makerOrder.token_id);
    const extraParams = {
        extra_address_param: { bits: makerOrder.extra_params.extra_address_param },
        extra_contract_param: { bits: makerOrder.extra_params.extra_contract_param },
        extra_u64_param: makerOrder.extra_params.extra_u64_param,
    };
    const output = {
        side: makerOrder.isBuySide ? SideInput.Buy : SideInput.Sell,
        maker: { bits: makerOrder.maker },
        collection: { bits: makerOrder.collection },
        token_id: subId,
        price: makerOrder.price,
        amount: makerOrder.amount,
        nonce: makerOrder.nonce,
        strategy: { bits: makerOrder.strategy },
        payment_asset: { bits: makerOrder.payment_asset },
        extra_params: extraParams,
    };
    return output;
}
function _convertToTakerOrder(takerOrder) {
    const subId = numberTo64Hex(takerOrder.token_id);
    const extraParams = {
        extra_address_param: { bits: takerOrder.extra_params.extra_address_param },
        extra_contract_param: { bits: takerOrder.extra_params.extra_contract_param },
        extra_u64_param: takerOrder.extra_params.extra_u64_param,
    };
    const output = {
        side: takerOrder.isBuySide ? SideInput.Buy : SideInput.Sell,
        taker: { bits: takerOrder.taker },
        maker: { bits: takerOrder.maker },
        collection: { bits: takerOrder.collection },
        token_id: subId,
        price: takerOrder.price,
        nonce: takerOrder.nonce,
        strategy: { bits: takerOrder.strategy },
        extra_params: extraParams,
    };
    return output;
}
function setup(contractId, provider, wallet) {
    return __awaiter(this, void 0, void 0, function* () {
        const _provider = yield Provider.create(provider);
        if (wallet) {
            if (typeof wallet === "string") {
                const _provider = yield Provider.create(provider);
                const walletUnlocked = new WalletUnlocked(wallet, _provider);
                return new ThunderExchange(contractId, walletUnlocked);
            }
            return new ThunderExchange(contractId, wallet);
        }
        return new ThunderExchange(contractId, _provider);
    });
}
function poolSetup(contractId, provider, wallet) {
    return __awaiter(this, void 0, void 0, function* () {
        const _provider = yield Provider.create(provider);
        if (wallet && typeof wallet === "string") {
            const _provider = yield Provider.create(provider);
            const walletUnlocked = new WalletUnlocked(wallet, _provider);
            return new Pool(contractId, walletUnlocked);
        }
        else if (wallet && typeof wallet !== "string") {
            return new Pool(contractId, wallet);
        }
        return new Pool(contractId, _provider);
    });
}
function erc721Setup(contractId, provider, wallet) {
    return __awaiter(this, void 0, void 0, function* () {
        const _provider = yield Provider.create(provider);
        if (wallet && typeof wallet === "string") {
            const _provider = yield Provider.create(provider);
            const walletUnlocked = new WalletUnlocked(wallet, _provider);
            return new NFTContract(contractId, walletUnlocked);
        }
        else if (wallet && typeof wallet !== "string") {
            return new NFTContract(contractId, wallet);
        }
        return new NFTContract(contractId, _provider);
    });
}
export function initialize(contractId, provider, wallet) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const contract = yield setup(contractId, provider, wallet);
            const call = yield contract.functions
                .initialize()
                .txParams({})
                .call();
            const { transactionResult } = yield call.waitForResult();
            return { transactionResult };
        }
        catch (err) {
            throw Error(`Exchange. initialize failed. Reason: ${err}`);
        }
    });
}
export function placeOrder(contractId, provider, wallet, order) {
    return __awaiter(this, void 0, void 0, function* () {
        if (order.isBuySide) {
            const { transactionResult } = yield _placeBuyOrder(contractId, provider, wallet, order);
            return { transactionResult };
        }
        const { transactionResult } = yield _placeSellOrder(contractId, provider, wallet, order);
        return { transactionResult };
    });
}
function _placeSellOrder(contractId, provider, wallet, order) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const contract = yield setup(contractId, provider, wallet);
            const _provider = yield Provider.create(provider);
            const _order = _convertToInput(order);
            const assetId = getMintedAssetId(order.collection, _order.token_id);
            const asset = { amount: order.amount, assetId: assetId };
            let strategy;
            strategy = strategyFixedPrice;
            // order.strategy == strategyFixedPrice.id.toB256() ?
            //     strategy = strategyFixedPrice:
            //     strategy = strategyAuction;
            const _collection = new NFTContract(order.collection, _provider);
            const _contract = new ThunderExchange(contract.id, _provider);
            // const { gasUsed } = await contract.functions
            //     .place_order(_order)
            //     .txParams({})
            //     .callParams({forward: asset})
            //     .addContracts([strategy, pool, executionManager, assetManager, _collection, _contract])
            //     .getTransactionCost();
            // const gasLimit = Number(gasUsed) * 1.5
            const call = yield contract.functions
                .place_order(_order)
                .txParams({})
                .callParams({ forward: asset })
                .addContracts([strategy, pool, executionManager, assetManager, _collection, _contract])
                .call();
            const { transactionResult } = yield call.waitForResult();
            return { transactionResult };
        }
        catch (err) {
            throw Error(`Exchange. _placeSellOrder failed. Reason: ${err}`);
        }
    });
}
function _placeBuyOrder(contractId, provider, wallet, order) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const contract = yield setup(contractId, provider, wallet);
            const _provider = yield Provider.create(provider);
            const _order = _convertToInput(order);
            let strategy;
            strategy = strategyFixedPrice;
            // order.strategy == strategyFixedPrice.id.toB256() ?
            //     strategy = strategyFixedPrice:
            //     strategy = strategyAuction;
            const _collection = new NFTContract(order.collection, _provider);
            const _contract = new ThunderExchange(contract.id, _provider);
            // const { gasUsed } = await contract.functions
            //     .place_order(_order)
            //     .txParams({})
            //     .addContracts([strategy, pool, executionManager, assetManager, _collection, _contract])
            //     .getTransactionCost();
            // const gasLimit = Number(gasUsed) * 1.5
            const call = yield contract.functions
                .place_order(_order)
                .txParams({})
                .addContracts([strategy, pool, executionManager, assetManager, _collection, _contract])
                .call();
            const { transactionResult } = yield call.waitForResult();
            return { transactionResult };
        }
        catch (err) {
            throw Error(`Exchange. _placeBuyOrder failed. Reason: ${err}`);
        }
    });
}
export function updateOrder(contractId, provider, wallet, order) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const contract = yield setup(contractId, provider, wallet);
            const _provider = yield Provider.create(provider);
            const _order = _convertToInput(order);
            let strategy;
            strategy = strategyFixedPrice;
            // order.strategy == strategyFixedPrice.id.toB256() ?
            //     strategy = strategyFixedPrice:
            //     strategy = strategyAuction;
            const _collection = new NFTContract(order.collection, _provider);
            const _contract = new ThunderExchange(contract.id, _provider);
            // const { gasUsed } = await contract.functions
            //     .update_order(_order)
            //     .txParams({})
            //     .addContracts([strategy, pool, executionManager, assetManager, _collection, _contract])
            //     .getTransactionCost();
            // const gasLimit = Number(gasUsed) * 1.5
            const call = yield contract.functions
                .update_order(_order)
                .txParams({})
                .addContracts([strategy, pool, executionManager, assetManager, _collection, _contract])
                .call();
            const { transactionResult } = yield call.waitForResult();
            return { transactionResult };
        }
        catch (err) {
            throw Error(`Exchange. updateOrder failed. Reason: ${err}`);
        }
    });
}
export function depositAndOffer(contractId, provider, wallet, order, requiredBidAmount, assetId, isUpdate) {
    return __awaiter(this, void 0, void 0, function* () {
        if (!order.isBuySide)
            throw Error("only buy side");
        try {
            const contract = yield setup(contractId, provider, wallet);
            const coin = { amount: requiredBidAmount, assetId: assetId };
            const _provider = yield Provider.create(provider);
            const _order = _convertToInput(order);
            let strategy;
            strategy = strategyFixedPrice;
            // order.strategy == strategyFixedPrice.id.toB256() ?
            //     strategy = strategyFixedPrice:
            //     strategy = strategyAuction;
            const _exchange = { bits: contractId };
            const _pool = { bits: pool.id.toB256() };
            const _asset = { bits: assetId };
            const _collection = new NFTContract(order.collection, _provider);
            const _contract = new ThunderExchange(contract.id, _provider);
            const script = new Script(bytecode, abi, wallet);
            // const { gasUsed } = await script.functions
            //     .main(_exchange, _pool, _order, requiredBidAmount, _asset, isUpdate)
            //     .txParams({})
            //     .callParams({forward: coin})
            //     .addContracts([strategy, pool, executionManager, assetManager, _collection, _contract])
            //     .getTransactionCost();
            // const gasLimit = Number(gasUsed) * 1.5
            const call = yield script.functions
                .main(_exchange, _pool, _order, requiredBidAmount, _asset, isUpdate)
                .txParams({})
                .callParams({ forward: coin })
                .addContracts([strategy, pool, executionManager, assetManager, _collection, _contract])
                .call();
            const { transactionResult } = yield call.waitForResult();
            return { transactionResult };
        }
        catch (err) {
            throw Error(`Exchange. depositAndOffer failed. Reason: ${err}`);
        }
    });
}
export function bulkListing(contractId, provider, wallet, orders, updateOrders) {
    return __awaiter(this, void 0, void 0, function* () {
        let calls = [];
        const contract = yield setup(contractId, provider, wallet);
        const _provider = yield Provider.create(provider);
        const _contract = new ThunderExchange(contract.id, _provider);
        const _contracts = [pool, executionManager, assetManager, _contract];
        if (orders.length !== 0) {
            for (const order of orders) {
                if (order.isBuySide)
                    continue;
                const makerOrder = _convertToInput(order);
                const assetId = getMintedAssetId(order.collection, makerOrder.token_id);
                const asset = { amount: order.amount, assetId: assetId };
                let strategy;
                strategy = strategyFixedPrice;
                // order.strategy == strategyFixedPrice.id.toB256() ?
                //     strategy = strategyFixedPrice:
                //     strategy = strategyAuction;
                const _collection = new NFTContract(makerOrder.collection.bits, _provider);
                if (!_contracts.includes(strategy))
                    _contracts.push(strategy);
                if (!_contracts.includes(_collection))
                    _contracts.push(_collection);
                const call = contract.functions
                    .place_order(makerOrder)
                    .txParams({})
                    .callParams({ forward: asset })
                    .addContracts([strategy, pool, executionManager, assetManager, _collection, _contract]);
                calls.push(call);
            }
        }
        if (updateOrders) {
            for (const order of updateOrders) {
                if (order.isBuySide)
                    continue;
                const makerOrder = _convertToInput(order);
                let strategy;
                strategy = strategyFixedPrice;
                // order.strategy == strategyFixedPrice.id.toB256() ?
                //     strategy = strategyFixedPrice:
                //     strategy = strategyAuction;
                const _collection = new NFTContract(makerOrder.collection.bits, _provider);
                if (!_contracts.includes(strategy))
                    _contracts.push(strategy);
                if (!_contracts.includes(_collection))
                    _contracts.push(_collection);
                const call = contract.functions
                    .update_order(makerOrder)
                    .txParams({})
                    .addContracts([strategy, pool, executionManager, assetManager, _collection, _contract]);
                calls.push(call);
            }
        }
        if (calls.length === 0)
            return null;
        // const { gasUsed } = await contract.multiCall(calls)
        //     .txParams({})
        //     .addContracts(_contracts)
        //     .getTransactionCost();
        // const gasLimit = Number(gasUsed) * 1.5
        const call = yield contract.multiCall(calls)
            .txParams({})
            .addContracts(_contracts)
            .call();
        const { transactionResult } = yield call.waitForResult();
        return { transactionResult };
    });
}
export function cancelOrder(contractId, provider, wallet, strategy, nonce, isBuySide) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            let side;
            isBuySide ?
                side = SideInput.Buy :
                side = SideInput.Sell;
            const _provider = yield Provider.create(provider);
            const contract = yield setup(contractId, provider, wallet);
            const _strategy = { bits: strategy };
            let strategyContract;
            strategyContract = strategyFixedPrice;
            // order.strategy == strategyFixedPrice.id.toB256() ?
            //     strategy = strategyFixedPrice:
            //     strategy = strategyAuction;
            if (isBuySide) {
                // const { gasUsed } = await contract.functions
                //     .cancel_order(_strategy, nonce, side)
                //     .addContracts([strategyContract, executionManager])
                //     .txParams({})
                //     .getTransactionCost();
                // const gasLimit = Number(gasUsed) * 1.5
                const call = yield contract.functions
                    .cancel_order(_strategy, nonce, side)
                    .addContracts([strategyContract, executionManager])
                    .txParams({})
                    .call();
                const { transactionResult } = yield call.waitForResult();
                return { transactionResult };
            }
            // const { gasUsed } = await contract.functions
            //     .cancel_order(_strategy, nonce, side)
            //     .addContracts([strategyContract, executionManager])
            //     .txParams({variableOutputs: 1})
            //     .getTransactionCost();
            // const gasLimit = Number(gasUsed) * 1.5
            const call = yield contract.functions
                .cancel_order(_strategy, nonce, side)
                .addContracts([strategyContract, executionManager])
                .txParams({ variableOutputs: 1 })
                .call();
            const { transactionResult } = yield call.waitForResult();
            return { transactionResult };
        }
        catch (err) {
            throw Error(`Exchange. cancelOrder failed. Reason: ${err}`);
        }
    });
}
export function bulkCancelOrder(contractId, provider, wallet, orders) {
    return __awaiter(this, void 0, void 0, function* () {
        let calls = [];
        const contract = yield setup(contractId, provider, wallet);
        for (const order of orders) {
            let side;
            order.isBuySide ?
                side = SideInput.Buy :
                side = SideInput.Sell;
            const _strategy = { bits: order.strategy };
            let strategyContract;
            strategyContract = strategyFixedPrice;
            // order.strategy == strategyFixedPrice.id.toB256() ?
            //     strategy = strategyFixedPrice:
            //     strategy = strategyAuction;
            if (order.isBuySide) {
                const call = contract.functions
                    .cancel_order(_strategy, order.nonce, side)
                    .addContracts([strategyContract, executionManager])
                    .txParams({});
                calls.push(call);
            }
            else {
                const call = contract.functions
                    .cancel_order(_strategy, order.nonce, side)
                    .addContracts([strategyContract, executionManager])
                    .txParams({ variableOutputs: 1 });
                calls.push(call);
            }
        }
        if (calls.length === 0)
            return null;
        // const { gasUsed } = await contract.multiCall(calls)
        //     .txParams({})
        //     .addContracts([strategyFixedPrice, executionManager])
        //     .getTransactionCost();
        // const gasLimit = Number(gasUsed) * 1.5
        const call = yield contract.multiCall(calls)
            .txParams({})
            .addContracts([strategyFixedPrice, executionManager])
            .call();
        const { transactionResult } = yield call.waitForResult();
        return { transactionResult };
    });
}
export function executeOrder(contractId, provider, wallet, order, assetId) {
    return __awaiter(this, void 0, void 0, function* () {
        const takerOrder = _convertToTakerOrder(order);
        if (order.isBuySide) {
            const { transactionResult } = yield _executeBuyOrder(contractId, provider, wallet, takerOrder, assetId);
            return { transactionResult };
        }
        const { transactionResult } = yield _executeSellOrder(contractId, provider, wallet, takerOrder);
        return { transactionResult };
    });
}
function _executeBuyOrder(contractId, provider, wallet, order, assetId) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const _provider = yield Provider.create(provider);
            const contract = yield setup(contractId, provider, wallet);
            const coin = { amount: order.price, assetId: assetId };
            let _strategy;
            _strategy = strategyFixedPrice;
            // order.strategy == strategyFixedPrice.id.toB256() ?
            //     strategy = strategyFixedPrice:
            //     strategy = strategyAuction;
            const _collection = new NFTContract(order.collection.bits, _provider);
            // const { gasUsed } = await contract.functions
            //     .execute_order(order)
            //     .txParams({variableOutputs: 4})
            //     .addContracts([_strategy, _collection, royaltyManager, executionManager])
            //     .callParams({forward: coin})
            //     .getTransactionCost();
            // const gasLimit = Number(gasUsed) * 1.5
            const call = yield contract.functions
                .execute_order(order)
                .txParams({ variableOutputs: 4 })
                .addContracts([_strategy, _collection, royaltyManager, executionManager])
                .callParams({ forward: coin })
                .call();
            const { transactionResult } = yield call.waitForResult();
            return { transactionResult };
        }
        catch (err) {
            throw Error(`Exchange. _executeBuyOrder failed. Reason: ${err}`);
        }
    });
}
function _executeSellOrder(contractId, provider, wallet, order) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const _provider = yield Provider.create(provider);
            const contract = yield setup(contractId, provider, wallet);
            const assetId = getMintedAssetId(order.collection.bits, order.token_id);
            console.log(assetId);
            const asset = { amount: 1, assetId: assetId };
            let _strategy;
            _strategy = strategyFixedPrice;
            // order.strategy == strategyFixedPrice.id.toB256() ?
            //     strategy = strategyFixedPrice:
            //     strategy = strategyAuction;
            const _collection = new NFTContract(order.collection.bits, _provider);
            // const { gasUsed } = await contract.functions
            //     .execute_order(order)
            //     .txParams({variableOutputs: 4})
            //     .callParams({forward: asset})
            //     .addContracts([_strategy, _collection, pool, assetManager, royaltyManager, executionManager])
            //     .getTransactionCost();
            // const gasLimit = Number(gasUsed) * 1.5
            const call = yield contract.functions
                .execute_order(order)
                .txParams({ variableOutputs: 4 })
                .callParams({ forward: asset })
                .addContracts([_strategy, _collection, pool, assetManager, royaltyManager, executionManager])
                .call();
            const { transactionResult } = yield call.waitForResult();
            return { transactionResult };
        }
        catch (err) {
            throw Error(`Exchange. _executeSellOrder failed. Reason: ${err}`);
        }
    });
}
export function bulkPurchase(contractId, provider, wallet, orders, assetId) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const contract = yield setup(contractId, provider, wallet);
            const _provider = yield Provider.create(provider);
            const _contracts = [pool, executionManager, assetManager, strategyFixedPrice, royaltyManager];
            const calls = [];
            for (const order of orders) {
                if (!order.isBuySide || order.nonce === 0)
                    continue;
                const takerOrderInput = _convertToTakerOrder(order);
                const coin = { amount: order.price, assetId: assetId };
                const _collection = new NFTContract(order.collection, _provider);
                if (!_contracts.some(contract => contract.id === _collection.id)) {
                    _contracts.push(_collection);
                }
                const call = contract.functions
                    .execute_order(takerOrderInput)
                    .txParams({ variableOutputs: 4 })
                    .addContracts([strategyFixedPrice, pool, executionManager, assetManager, _collection, royaltyManager])
                    .callParams({ forward: coin });
                calls.push(call);
            }
            // Disabled gas limit estimation to speed up the process
            // //   Estimate gas for the batched transaction
            //   const { gasUsed } = await contract.multiCall(calls)
            //     .txParams({})
            //     .addContracts(_contracts)
            //     .getTransactionCost();
            //   const gasLimit = Math.floor(Number(gasUsed) * 1.5);
            //   Execute the batch transaction
            const result = yield contract.multiCall(calls)
                .txParams({})
                .addContracts(_contracts)
                .call();
            const { transactionResult } = yield result.waitForResult();
            return { transactionResult };
        }
        catch (err) {
            console.error("Exchange.bulkPurchase failed:", err);
            throw Error(`Exchange.bulkPurchase failed. Reason: ${err.message || err}`);
        }
    });
}
export function setPool(contractId, provider, wallet, pool) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const contract = yield setup(contractId, provider, wallet);
            const _pool = { bits: pool };
            const call = yield contract.functions
                .set_pool(_pool)
                .txParams({})
                .call();
            const { transactionResult } = yield call.waitForResult();
            return { transactionResult };
        }
        catch (err) {
            throw Error(`Exchange. setPool failed. Reason: ${err}`);
        }
    });
}
export function setExecutionManager(contractId, provider, wallet, executionManager) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const contract = yield setup(contractId, provider, wallet);
            const _executionManager = { bits: executionManager };
            const call = yield contract.functions
                .set_execution_manager(_executionManager)
                .txParams({})
                .call();
            const { transactionResult } = yield call.waitForResult();
            return { transactionResult };
        }
        catch (err) {
            throw Error(`Exchange. setExecutionManager failed. Reason: ${err}`);
        }
    });
}
export function setRoyaltyManager(contractId, provider, wallet, royaltyManager) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const contract = yield setup(contractId, provider, wallet);
            const _royaltyManager = { bits: royaltyManager };
            const call = yield contract.functions
                .set_royalty_manager(_royaltyManager)
                .txParams({})
                .call();
            const { transactionResult } = yield call.waitForResult();
            return { transactionResult };
        }
        catch (err) {
            throw Error(`Exchange. setRoyaltyManager failed. Reason: ${err}`);
        }
    });
}
export function setAssetManager(contractId, provider, wallet, assetManager) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const contract = yield setup(contractId, provider, wallet);
            const _assetManager = { bits: assetManager };
            const call = yield contract.functions
                .set_asset_manager(_assetManager)
                .txParams({})
                .call();
            const { transactionResult } = yield call.waitForResult();
            return { transactionResult };
        }
        catch (err) {
            throw Error(`Exchange. setAssetManager failed. Reason: ${err}`);
        }
    });
}
export function setProtocolFeeRecipient(contractId, provider, wallet, protocolFeeRecipient, isRecipientAddress) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            let _protocolFeeRecipient;
            isRecipientAddress ?
                _protocolFeeRecipient = { Address: { bits: protocolFeeRecipient } } :
                _protocolFeeRecipient = { ContractId: { bits: protocolFeeRecipient } };
            const contract = yield setup(contractId, provider, wallet);
            const call = yield contract.functions
                .set_protocol_fee_recipient(_protocolFeeRecipient)
                .txParams({})
                .call();
            const { transactionResult } = yield call.waitForResult();
            return { transactionResult };
        }
        catch (err) {
            throw Error(`Exchange. setProtocolFeeRecipient failed. Reason: ${err}`);
        }
    });
}
export function getPool(contractId, provider) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const contract = yield setup(contractId, provider);
            const { value } = yield contract.functions
                .get_pool()
                .simulate();
            return { value };
        }
        catch (err) {
            console.error("Exchange: " + err);
            return { err };
        }
    });
}
export function getExecutionManager(contractId, provider) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const contract = yield setup(contractId, provider);
            const { value } = yield contract.functions
                .get_execution_manager()
                .simulate();
            return { value };
        }
        catch (err) {
            console.error("Exchange: " + err);
            return { err };
        }
    });
}
export function getRoyaltyManager(contractId, provider) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const contract = yield setup(contractId, provider);
            const { value } = yield contract.functions
                .get_royalty_manager()
                .simulate();
            return { value };
        }
        catch (err) {
            console.error("Exchange: " + err);
            return { err };
        }
    });
}
export function getAssetManager(contractId, provider) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const contract = yield setup(contractId, provider);
            const { value } = yield contract.functions
                .get_asset_manager()
                .simulate();
            return { value };
        }
        catch (err) {
            console.error("Exchange: " + err);
            return { err };
        }
    });
}
export function getProtocolFeeRecipient(contractId, provider) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const contract = yield setup(contractId, provider);
            const { value } = yield contract.functions
                .get_protocol_fee_recipient()
                .simulate();
            return { value };
        }
        catch (err) {
            console.error("Exchange: " + err);
            return { err };
        }
    });
}
export function owner(contractId, provider) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const contract = yield setup(contractId, provider);
            const { value } = yield contract.functions
                .owner()
                .simulate();
            return { value };
        }
        catch (err) {
            console.error("Exchange: " + err);
            return { err };
        }
    });
}
export function transferOwnership(contractId, provider, wallet, newOwner) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const contract = yield setup(contractId, provider, wallet);
            const _newOwner = { Address: { bits: newOwner } };
            const call = yield contract.functions
                .transfer_ownership(_newOwner)
                .txParams({})
                .call();
            const { transactionResult } = yield call.waitForResult();
            return { transactionResult };
        }
        catch (err) {
            throw Error(`Exchange. transferOwnership failed. Reason: ${err}`);
        }
    });
}
export function renounceOwnership(contractId, provider, wallet) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const contract = yield setup(contractId, provider, wallet);
            const call = yield contract.functions
                .renounce_ownership()
                .txParams({})
                .call();
            const { transactionResult } = yield call.waitForResult();
            return { transactionResult };
        }
        catch (err) {
            throw Error(`Exchange. renounceOwnership failed. Reason: ${err}`);
        }
    });
}
