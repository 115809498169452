import React from "react";
import { IconNotFound, IconNotFound2 } from "icons";

const NotFound = ({ children }: { children?: React.ReactNode }) => {
  return (
    <div className="m-auto flex flex-col text-gray-light items-center gap-9 py-20">
      <IconNotFound className="" />
      <div className="body-large">{children ?? "Nothing found."}</div>
    </div>
  );
};

const NotFoundMarketplace = () => {
  return (
    <div className="m-auto flex flex-col text-gray-light items-center gap-7 py-20 w-full max-w-[330px]">
      <IconNotFound2 className="" />
      <div className="body-large text-center">Collections coming soon! Check back shortly for first drops.</div>
    </div>
  );
};

export default Object.assign(NotFound, {
  Marketplace: NotFoundMarketplace,
});
