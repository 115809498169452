var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Provider, WalletUnlocked } from "fuels";
import { Pool } from "../../types/pool";
import { AssetManager } from "../../types/asset_manager/";
function setup(contractId, provider, wallet) {
    return __awaiter(this, void 0, void 0, function* () {
        const _provider = yield Provider.create(provider);
        if (wallet && typeof wallet === "string") {
            const _provider = yield Provider.create(provider);
            const walletUnlocked = new WalletUnlocked(wallet, _provider);
            return new Pool(contractId, walletUnlocked);
        }
        else if (wallet && typeof wallet !== "string") {
            return new Pool(contractId, wallet);
        }
        return new Pool(contractId, _provider);
    });
}
export function initialize(contractId, provider, wallet, exchange, assetManager) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const contract = yield setup(contractId, provider, wallet);
            const _exchange = { bits: exchange };
            const _Pool = { bits: assetManager };
            const call = yield contract.functions
                .initialize(_exchange, _Pool)
                .txParams({})
                .call();
            const { transactionResult } = yield call.waitForResult();
            return { transactionResult };
        }
        catch (err) {
            throw Error(`Pool. initialize failed. Reason: ${err}`);
        }
    });
}
export function totalSupply(contractId, provider, asset) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const _asset = { bits: asset };
            const contract = yield setup(contractId, provider);
            const { value } = yield contract.functions
                .total_supply(_asset)
                .simulate();
            return { value };
        }
        catch (err) {
            console.error("Pool: " + err);
            return { err };
        }
    });
}
export function balanceOf(contractId, provider, wallet, account, asset) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const _account = { Address: { bits: account } };
            const _asset = { bits: asset };
            const contract = yield setup(contractId, provider, wallet);
            const { value } = yield contract.functions
                .balance_of(_account, _asset)
                .simulate();
            return { value };
        }
        catch (err) {
            console.error("Pool: " + err);
            return { err };
        }
    });
}
export function deposit(contractId, provider, wallet, amount, assetId, assetManagerAddr) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const contract = yield setup(contractId, provider, wallet);
            const coin = { amount: amount, assetId: assetId };
            const _provider = yield Provider.create(provider);
            const assetManager = new AssetManager(assetManagerAddr, _provider);
            const { gasUsed } = yield contract.functions
                .deposit()
                .txParams({})
                .addContracts([assetManager])
                .callParams({ forward: coin })
                .getTransactionCost();
            const gasLimit = Number(gasUsed) * 1.5;
            const call = yield contract.functions
                .deposit()
                .txParams({ gasLimit })
                .addContracts([assetManager])
                .callParams({ forward: coin })
                .call();
            const { transactionResult } = yield call.waitForResult();
            return { transactionResult };
        }
        catch (err) {
            throw Error(`Pool. deposit failed. Reason: ${err}`);
        }
    });
}
export function withdraw(contractId, provider, wallet, amount, assetId, assetManagerAddr) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const contract = yield setup(contractId, provider, wallet);
            const _asset = { bits: assetId };
            const _provider = yield Provider.create(provider);
            const assetManager = new AssetManager(assetManagerAddr, _provider);
            const { gasUsed } = yield contract.functions
                .withdraw(_asset, amount)
                .txParams({ variableOutputs: 1 })
                .addContracts([assetManager])
                .getTransactionCost();
            const gasLimit = Number(gasUsed) * 1.5;
            const call = yield contract.functions
                .withdraw(_asset, amount)
                .txParams({ variableOutputs: 1, gasLimit })
                .addContracts([assetManager])
                .call();
            const { transactionResult } = yield call.waitForResult();
            return { transactionResult };
        }
        catch (err) {
            throw Error(`Pool. withdraw failed. Reason: ${err}`);
        }
    });
}
export function transferFrom(contractId, provider, wallet, from, to, asset, amount) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const contract = yield setup(contractId, provider, wallet);
            const _from = { Address: { bits: from } };
            const _to = { Address: { bits: to } };
            const _asset = { bits: asset };
            const call = yield contract.functions
                .transfer_from(_from, _to, _asset, amount)
                .txParams({})
                .call();
            const { transactionResult } = yield call.waitForResult();
            return { transactionResult };
        }
        catch (err) {
            throw Error(`Pool. transferFrom failed. Reason: ${err}`);
        }
    });
}
export function setAssetManager(contractId, provider, wallet, assetManager) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const contract = yield setup(contractId, provider, wallet);
            const _Pool = { bits: assetManager };
            const call = yield contract.functions
                .set_asset_manager(_Pool)
                .txParams({ variableOutputs: 1 })
                .call();
            const { transactionResult } = yield call.waitForResult();
            return { transactionResult };
        }
        catch (err) {
            throw Error(`Pool. setAssetManager failed. Reason: ${err}`);
        }
    });
}
export function getTransferManager(contractId, provider) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const contract = yield setup(contractId, provider);
            const { value } = yield contract.functions
                .get_asset_manager()
                .simulate();
            return { value };
        }
        catch (err) {
            throw Error(`Pool. getTransferManager failed. Reason: ${err}`);
        }
    });
}
export function getExchange(contractId, provider) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const contract = yield setup(contractId, provider);
            const { value } = yield contract.functions
                .get_exchange()
                .simulate();
            return { value };
        }
        catch (err) {
            throw Error(`Pool. getExchange failed. Reason: ${err}`);
        }
    });
}
export function owner(contractId, provider) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const contract = yield setup(contractId, provider);
            const { value } = yield contract.functions
                .owner()
                .simulate();
            return { value };
        }
        catch (err) {
            console.error("Pool: " + err);
            return { err };
        }
    });
}
export function transferOwnership(contractId, provider, wallet, newOwner) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const contract = yield setup(contractId, provider, wallet);
            const _newOwner = { Address: { bits: newOwner } };
            const call = yield contract.functions
                .transfer_ownership(_newOwner)
                .txParams({})
                .call();
            const { transactionResult } = yield call.waitForResult();
            return { transactionResult };
        }
        catch (err) {
            throw Error(`Pool. transferOwnership failed. Reason: ${err}`);
        }
    });
}
export function renounceOwnership(contractId, provider, wallet) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const contract = yield setup(contractId, provider, wallet);
            const call = yield contract.functions
                .renounce_ownership()
                .txParams({})
                .call();
            const { transactionResult } = yield call.waitForResult();
            return { transactionResult };
        }
        catch (err) {
            throw Error(`Pool. renounceOwnership failed. Reason: ${err}`);
        }
    });
}
