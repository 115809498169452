/* Autogenerated file. Do not edit manually. */
/* eslint-disable max-classes-per-file */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/consistent-type-imports */
/*
  Fuels version: 0.95.0
*/
import { Contract, Interface } from "fuels";
export var AccessErrorInput;
(function (AccessErrorInput) {
    AccessErrorInput["CannotReinitialized"] = "CannotReinitialized";
    AccessErrorInput["NotOwner"] = "NotOwner";
})(AccessErrorInput || (AccessErrorInput = {}));
;
export var AccessErrorOutput;
(function (AccessErrorOutput) {
    AccessErrorOutput["CannotReinitialized"] = "CannotReinitialized";
    AccessErrorOutput["NotOwner"] = "NotOwner";
})(AccessErrorOutput || (AccessErrorOutput = {}));
;
export var AssetManagerErrorsInput;
(function (AssetManagerErrorsInput) {
    AssetManagerErrorsInput["Initialized"] = "Initialized";
    AssetManagerErrorsInput["OnlyOwner"] = "OnlyOwner";
    AssetManagerErrorsInput["AssetAlreadySupported"] = "AssetAlreadySupported";
    AssetManagerErrorsInput["AssetNotSupported"] = "AssetNotSupported";
    AssetManagerErrorsInput["ZeroLengthVec"] = "ZeroLengthVec";
    AssetManagerErrorsInput["IndexOutOfBound"] = "IndexOutOfBound";
})(AssetManagerErrorsInput || (AssetManagerErrorsInput = {}));
;
export var AssetManagerErrorsOutput;
(function (AssetManagerErrorsOutput) {
    AssetManagerErrorsOutput["Initialized"] = "Initialized";
    AssetManagerErrorsOutput["OnlyOwner"] = "OnlyOwner";
    AssetManagerErrorsOutput["AssetAlreadySupported"] = "AssetAlreadySupported";
    AssetManagerErrorsOutput["AssetNotSupported"] = "AssetNotSupported";
    AssetManagerErrorsOutput["ZeroLengthVec"] = "ZeroLengthVec";
    AssetManagerErrorsOutput["IndexOutOfBound"] = "IndexOutOfBound";
})(AssetManagerErrorsOutput || (AssetManagerErrorsOutput = {}));
;
const abi = {
    "programType": "contract",
    "specVersion": "1",
    "encodingVersion": "1",
    "concreteTypes": [
        {
            "type": "()",
            "concreteTypeId": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d"
        },
        {
            "type": "bool",
            "concreteTypeId": "b760f44fa5965c2474a3b471467a22c43185152129295af588b022ae50b50903"
        },
        {
            "type": "enum errors::AssetManagerErrors",
            "concreteTypeId": "763887443d2a6ae25bd40b563190248c23a7e47981142ca06968489e63950963",
            "metadataTypeId": 1
        },
        {
            "type": "enum libraries::ownable::AccessError",
            "concreteTypeId": "b125b3c353d0e20ba2296785cc1653908f3c1fb7e67e055938c31fd0e1bb8086",
            "metadataTypeId": 2
        },
        {
            "type": "enum std::identity::Identity",
            "concreteTypeId": "ab7cd04e05be58e3fc15d424c2c4a57f824a2a2d97d67252440a3925ebdc1335",
            "metadataTypeId": 3
        },
        {
            "type": "enum std::option::Option<enum std::identity::Identity>",
            "concreteTypeId": "253aea1197e8005518365bd24c8bc31f73a434fac0f7350e57696edfdd4850c2",
            "metadataTypeId": 4,
            "typeArguments": [
                "ab7cd04e05be58e3fc15d424c2c4a57f824a2a2d97d67252440a3925ebdc1335"
            ]
        },
        {
            "type": "enum std::option::Option<struct std::asset_id::AssetId>",
            "concreteTypeId": "191bf2140761b3c5ab6c43992d162bb3dc9d7f2272b2ee5f5eeea411ddedcd32",
            "metadataTypeId": 4,
            "typeArguments": [
                "c0710b6731b1dd59799cf6bef33eee3b3b04a2e40e80a0724090215bbf2ca974"
            ]
        },
        {
            "type": "struct libraries::ownable::OwnershipRenounced",
            "concreteTypeId": "6dd047dcef1fafba87b54170a1ee4a99a8ae37746e594cbc548c5315368b20c3",
            "metadataTypeId": 6
        },
        {
            "type": "struct libraries::ownable::OwnershipSet",
            "concreteTypeId": "8416605cb05f0e41791636365d6644426eb8723c9bbdfc940b41652fc5bb7e61",
            "metadataTypeId": 7
        },
        {
            "type": "struct libraries::ownable::OwnershipTransferred",
            "concreteTypeId": "947c52bd18fcf648398d82d4d7b3cb539ef96fec3211f41a6e733a8a4f02f67e",
            "metadataTypeId": 8
        },
        {
            "type": "struct std::asset_id::AssetId",
            "concreteTypeId": "c0710b6731b1dd59799cf6bef33eee3b3b04a2e40e80a0724090215bbf2ca974",
            "metadataTypeId": 10
        },
        {
            "type": "u64",
            "concreteTypeId": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0"
        }
    ],
    "metadataTypes": [
        {
            "type": "b256",
            "metadataTypeId": 0
        },
        {
            "type": "enum errors::AssetManagerErrors",
            "metadataTypeId": 1,
            "components": [
                {
                    "name": "Initialized",
                    "typeId": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d"
                },
                {
                    "name": "OnlyOwner",
                    "typeId": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d"
                },
                {
                    "name": "AssetAlreadySupported",
                    "typeId": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d"
                },
                {
                    "name": "AssetNotSupported",
                    "typeId": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d"
                },
                {
                    "name": "ZeroLengthVec",
                    "typeId": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d"
                },
                {
                    "name": "IndexOutOfBound",
                    "typeId": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d"
                }
            ]
        },
        {
            "type": "enum libraries::ownable::AccessError",
            "metadataTypeId": 2,
            "components": [
                {
                    "name": "CannotReinitialized",
                    "typeId": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d"
                },
                {
                    "name": "NotOwner",
                    "typeId": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d"
                }
            ]
        },
        {
            "type": "enum std::identity::Identity",
            "metadataTypeId": 3,
            "components": [
                {
                    "name": "Address",
                    "typeId": 9
                },
                {
                    "name": "ContractId",
                    "typeId": 11
                }
            ]
        },
        {
            "type": "enum std::option::Option",
            "metadataTypeId": 4,
            "components": [
                {
                    "name": "None",
                    "typeId": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d"
                },
                {
                    "name": "Some",
                    "typeId": 5
                }
            ],
            "typeParameters": [
                5
            ]
        },
        {
            "type": "generic T",
            "metadataTypeId": 5
        },
        {
            "type": "struct libraries::ownable::OwnershipRenounced",
            "metadataTypeId": 6,
            "components": [
                {
                    "name": "previous_owner",
                    "typeId": 3
                }
            ]
        },
        {
            "type": "struct libraries::ownable::OwnershipSet",
            "metadataTypeId": 7,
            "components": [
                {
                    "name": "new_owner",
                    "typeId": 3
                }
            ]
        },
        {
            "type": "struct libraries::ownable::OwnershipTransferred",
            "metadataTypeId": 8,
            "components": [
                {
                    "name": "new_owner",
                    "typeId": 3
                },
                {
                    "name": "previous_owner",
                    "typeId": 3
                }
            ]
        },
        {
            "type": "struct std::address::Address",
            "metadataTypeId": 9,
            "components": [
                {
                    "name": "bits",
                    "typeId": 0
                }
            ]
        },
        {
            "type": "struct std::asset_id::AssetId",
            "metadataTypeId": 10,
            "components": [
                {
                    "name": "bits",
                    "typeId": 0
                }
            ]
        },
        {
            "type": "struct std::contract_id::ContractId",
            "metadataTypeId": 11,
            "components": [
                {
                    "name": "bits",
                    "typeId": 0
                }
            ]
        }
    ],
    "functions": [
        {
            "inputs": [
                {
                    "name": "asset",
                    "concreteTypeId": "c0710b6731b1dd59799cf6bef33eee3b3b04a2e40e80a0724090215bbf2ca974"
                }
            ],
            "name": "add_asset",
            "output": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d",
            "attributes": [
                {
                    "name": "doc-comment",
                    "arguments": [
                        " Adds asset into supported assets vec"
                    ]
                },
                {
                    "name": "storage",
                    "arguments": [
                        "read",
                        "write"
                    ]
                }
            ]
        },
        {
            "inputs": [],
            "name": "get_count_supported_assets",
            "output": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0",
            "attributes": [
                {
                    "name": "storage",
                    "arguments": [
                        "read"
                    ]
                }
            ]
        },
        {
            "inputs": [
                {
                    "name": "index",
                    "concreteTypeId": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0"
                }
            ],
            "name": "get_supported_asset",
            "output": "191bf2140761b3c5ab6c43992d162bb3dc9d7f2272b2ee5f5eeea411ddedcd32",
            "attributes": [
                {
                    "name": "doc-comment",
                    "arguments": [
                        " Returns a supported asset at the index"
                    ]
                },
                {
                    "name": "storage",
                    "arguments": [
                        "read"
                    ]
                }
            ]
        },
        {
            "inputs": [],
            "name": "initialize",
            "output": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d",
            "attributes": [
                {
                    "name": "doc-comment",
                    "arguments": [
                        " Initializes the contract and sets the owner"
                    ]
                },
                {
                    "name": "storage",
                    "arguments": [
                        "read",
                        "write"
                    ]
                }
            ]
        },
        {
            "inputs": [
                {
                    "name": "asset",
                    "concreteTypeId": "c0710b6731b1dd59799cf6bef33eee3b3b04a2e40e80a0724090215bbf2ca974"
                }
            ],
            "name": "is_asset_supported",
            "output": "b760f44fa5965c2474a3b471467a22c43185152129295af588b022ae50b50903",
            "attributes": [
                {
                    "name": "doc-comment",
                    "arguments": [
                        " Returns true or false based on whether the asset is supported or not"
                    ]
                },
                {
                    "name": "storage",
                    "arguments": [
                        "read"
                    ]
                }
            ]
        },
        {
            "inputs": [],
            "name": "owner",
            "output": "253aea1197e8005518365bd24c8bc31f73a434fac0f7350e57696edfdd4850c2",
            "attributes": [
                {
                    "name": "storage",
                    "arguments": [
                        "read"
                    ]
                }
            ]
        },
        {
            "inputs": [
                {
                    "name": "index",
                    "concreteTypeId": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0"
                }
            ],
            "name": "remove_asset",
            "output": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d",
            "attributes": [
                {
                    "name": "doc-comment",
                    "arguments": [
                        " Removes asset from supported assets vec"
                    ]
                },
                {
                    "name": "storage",
                    "arguments": [
                        "read",
                        "write"
                    ]
                }
            ]
        },
        {
            "inputs": [],
            "name": "renounce_ownership",
            "output": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d",
            "attributes": [
                {
                    "name": "storage",
                    "arguments": [
                        "read",
                        "write"
                    ]
                }
            ]
        },
        {
            "inputs": [
                {
                    "name": "new_owner",
                    "concreteTypeId": "ab7cd04e05be58e3fc15d424c2c4a57f824a2a2d97d67252440a3925ebdc1335"
                }
            ],
            "name": "transfer_ownership",
            "output": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d",
            "attributes": [
                {
                    "name": "storage",
                    "arguments": [
                        "read",
                        "write"
                    ]
                }
            ]
        }
    ],
    "loggedTypes": [
        {
            "logId": "12764806370357731851",
            "concreteTypeId": "b125b3c353d0e20ba2296785cc1653908f3c1fb7e67e055938c31fd0e1bb8086"
        },
        {
            "logId": "8518707422325009122",
            "concreteTypeId": "763887443d2a6ae25bd40b563190248c23a7e47981142ca06968489e63950963"
        },
        {
            "logId": "9517900813706399297",
            "concreteTypeId": "8416605cb05f0e41791636365d6644426eb8723c9bbdfc940b41652fc5bb7e61"
        },
        {
            "logId": "7912903559520169914",
            "concreteTypeId": "6dd047dcef1fafba87b54170a1ee4a99a8ae37746e594cbc548c5315368b20c3"
        },
        {
            "logId": "10699517786846983752",
            "concreteTypeId": "947c52bd18fcf648398d82d4d7b3cb539ef96fec3211f41a6e733a8a4f02f67e"
        }
    ],
    "messagesTypes": [],
    "configurables": []
};
const storageSlots = [
    {
        "key": "1d63cc2495bbf5570c9a6d7f632018dc033107e7f4452405c44601bb771a4a5d",
        "value": "0000000000000000000000000000000000000000000000000000000000000000"
    },
    {
        "key": "1d63cc2495bbf5570c9a6d7f632018dc033107e7f4452405c44601bb771a4a5e",
        "value": "0000000000000000000000000000000000000000000000000000000000000000"
    },
    {
        "key": "38a1b038206f348ec947ba5770c6d713148c9d8bb0b312f47acc88777da1c09d",
        "value": "0000000000000000000000000000000000000000000000000000000000000000"
    }
];
export class AssetManagerInterface extends Interface {
    constructor() {
        super(abi);
    }
}
export class AssetManager extends Contract {
    constructor(id, accountOrProvider) {
        super(id, abi, accountOrProvider);
    }
}
AssetManager.abi = abi;
AssetManager.storageSlots = storageSlots;
